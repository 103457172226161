import request from "@/utils/request";

/**
 * @description: 获取筛选列表
 */
export function getFiltersList(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}
