import request from "@/utils/request";

// 获取岗位详情
export function getCategories(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}
