<template>
  <div class="wrapper">
    <div
      v-for="item in tagList"
      :key="item.tagValue"
      :class="[indexTag == item.tagValue ? 'checked' : 'unchecked', 'item']"
      @click="handleClick(item)"
    >
      <div class="text">
        {{ item.label }}
      </div>
      <div class="bar" v-if="indexTag === item.tagValue"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      indexTag: 2,
      tagList: [
        { tagValue: 2, label: "最新" },
        { tagValue: 1, label: "推荐" },
      ],
    };
  },
  props: {},
  methods: {
    handleClick(item) {
      const { tagValue } = item;
      this.indexTag = tagValue;
      this.$emit("change", tagValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.checked {
  height: 0.34rem;
  font-size: 0.34rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.34rem;
  .bar {
    height: 0.08rem;
    background: #3c37d2;
    border-radius: 0.04rem;
    opacity: 0.7;
    margin-top: -0.06rem;
  }
}
.unchecked {
  height: 0.34rem;
  font-size: 0.28rem;
  color: #666666;
  line-height: 0.34rem;
}
.item {
  margin-right: 0.3rem;
}
</style>
