<!--
 * @Date: 2021-06-02 09:01:57
 * @LastEditTime: 2022-03-02 16:30:08
 * @Description: 底部弹窗
 *  
-->

<template>
  <div @touchmove.once.prevent @tap.stop.prevent>
    <div
      class="tui-popup-class tui-bottom-popup"
      :class="{ 'tui-popup-show': show, 'tui-popup-radius': radius }"
      :style="{
        backgroundColor: backgroundColor,
        height: height ? height + 'rpx' : 'auto',
        zIndex: zIndex,
        transform: `translate3d(0, ${show ? translateY : '100%'}, 0)`,
      }"
    >
      <div v-show="showCloseIcon" class="close-icon" @click="handleClose">
        <van-icon name="cross" />
      </div>
      <slot></slot>
    </div>
    <div
      @click.stop.prevent
      class="tui-popup-mask"
      :class="[show ? 'tui-mask-show' : '']"
      :style="{ zIndex: maskZIndex }"
      v-if="mask"
      @click="handleMaskClick"
    ></div>
  </div>
</template>

<script>
export default {
  name: "tuiBottomPopup",
  data(){
    return{
      scrollTop:null
    }
  },
  props: {
    //是否需要mask
    mask: {
      type: Boolean,
      default: true,
    },
    //点击遮罩关闭
    maskClose: {
      type: Boolean,
      default: true,
    },
    //控制显示
    show: {
      type: Boolean,
      default: false,
    },
    //背景颜色
    backgroundColor: {
      type: String,
      default: "#fff",
    },
    //高度 rpx
    height: {
      type: Number,
      default: 0,
    },
    //设置圆角
    radius: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: [Number, String],
      default: 997,
    },
    maskZIndex: {
      type: [Number, String],
      default: 996,
    },
    //弹层显示时，垂直方向移动的距离
    translateY: {
      type: String,
      default: "0",
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(val) {
      if(val){
        this.stopScroll()
      }else{
        this.canScroll()
      }
    },
  },
  methods: {
    //禁止滚动
    stopScroll() {
      let _scrollTop = document.scrollingElement.scrollTop;
      this.scrollTop = _scrollTop;
      document.body.classList.add("dialog");
      document.body.style.top = -_scrollTop + "px";
    },
    /***取消滑动限制***/
    canScroll() {
      document.body.classList.remove("dialog");
      document.scrollingElement.scrollTop = this.scrollTop;
    },

    handleClose() {
      if (!this.show) {
        return;
      }
      this.$emit("update:show", false);
      this.$emit("close", {});
    },
    handleMaskClick() {
      if (!this.maskClose) return;
      this.handleClose();
    },
  },
};
</script>

<style scoped>
.tui-bottom-popup {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transform-origin: center;
  transition: all 0.3s ease-in-out;
  min-height: 0.2rem;
}
.close-icon {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}

.tui-popup-radius {
  border-top-left-radius: 0.24rem;
  border-top-right-radius: 0.24rem;
  padding-bottom: env(safe-area-inset-bottom);
  overflow: hidden;
}

.tui-popup-show {
  opacity: 1;
  /* transform: translate3d(0, 0, 0); */
}

.tui-popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.tui-mask-show {
  opacity: 1;
  visibility: visible;
}
</style>
