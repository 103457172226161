<template>
  <van-popup
    :value="show"
    closeable
    close-icon-position="top-left"
    position="bottom"
    @click-overlay="handleClose"
    :close-on-click-overlay="false"
  >
    <div class="desired-position">
      <!-- 已选职位 -->
      <div class="selected-position-container flex-column">
        <div class="header-container flex-row">
          <div class="title">已选条件·{{ selectArr.length }}</div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="options-container">
        <!-- left -->
        <div class="root-list">
          <div class="category-list">
            <div
              :class="[
                'category-item',
                currentIndex == index ? 'isSelected' : '',
              ]"
              :border="false"
              v-for="(cate, index) in categories"
              @click="select(index)"
              :key="index"
            >
              <div class="cate-title">{{ cate.labelDesc }}</div>
            </div>
          </div>
        </div>
        <!-- right -->
        <div class="category-content">
          <div class="category-list">
            <div
              :class="[
                'item',
                selectArrDesc.indexOf(subCate.labelDesc) > -1
                  ? 'isSelected'
                  : '',
              ]"
              :border="false"
              v-for="(subCate, index) in subCategories"
              @click="selectSub(index)"
              :key="index"
            >
              <div class="sub-title">{{ subCate.labelDesc }}</div>
              <div
                class="selectIcon"
                v-if="selectArrDesc.indexOf(subCate.labelDesc) > -1"
              >
                <img class="img-xuanzhong" src="@/assets/icon_xuanzhong.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="clear" @click="onClear">重置</div>
        <div class="sure" @click="onSure">确定</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// 在单独构建的版本中辅助函数为 Vuex.mapState
import findIndex from "lodash/findIndex";
import { categories, subCategories } from "./selectFilter";
import { getFiltersList } from "@/api/recruit/jobLabel";

import customBottomPopup from "@/components/custom-bottom-popup";

export default {
  data() {
    return {
      //求职意向
      expectPositionMap: [], //期望职位
      addIn: true,
      selectArr: [],
      selectLength: 0,
      flag: 1, //标识（1-新增，2-修改，3-删除)

      categories: [...categories],
      currentIndex: 0,
      subCreentIndex: 0,
      subCategories: [...subCategories],
    };
  },
  components: { customBottomPopup },
  props: {
    show: Boolean,
    height: {
      default: 1000,
      type: Number,
    },
    mask: {
      default: true,
      type: Boolean,
    },
    maskZIndex: {
      default: 9996,
      type: Number,
    },
    zIndex: {
      default: 9997,
      type: Number,
    },
    title: {
      default: "请选择",
      type: String,
    },
    filterType: {
      default: "workDescription",
      type: String,
    },
  },
  computed: {
    selectArrDesc() {
      return this.selectArr.map((item) => {
        return item.expectPosition;
      });
    },
  },

  mounted() {
    const params = {
      apiName: "/recruit/job-label/queryJobLabel",
      paramMap: {},
      requestType: 1,
    };
    getFiltersList(params).then((res) => {
      for (let i in this.categories) {
        let labelCode = this.categories[i].labelCode;
        //1-工作描述 2-面试要求 3，4，5-福利待遇
        if ("workDescription" == labelCode) {
          this.categories[i].childJobLabelVOList = res.data.listMap[1];
        } else if ("interviewRequirements" == labelCode) {
          this.categories[i].childJobLabelVOList = res.data.listMap[2];
        } else if ("jobWelfare" == labelCode) {
          this.categories[i].childJobLabelVOList = res.data.listMap[3]
            .concat(res.data.listMap[4])
            .concat(res.data.listMap[5]);
        }
      }
    }); //获取标签筛选列表
  },

  methods: {
    /**
     * @description: 删除已选职位
     * @param {*} i
     * @return {*}
     */

    deleteBtn(i) {
      this.selectArr.splice(i, 1);
    },
    select(i) {
      this.currentIndex = i;
      this.subCreentIndex = 0;
      this.subCategories = this.categories[i].childJobLabelVOList;
    },
    selectSub(i) {
      this.subCreentIndex = i;

      const that = this;
      var a = findIndex(that.selectArr, function (o) {
        return o.expectPosition == that.subCategories[i].labelDesc;
      });
      if (a > -1) {
        this.selectArr.splice(a, 1);
        return;
      } else {
        //薪资单选其他多选
        if ("jobSalary" == this.categories[this.currentIndex].labelCode) {
          for (let i in this.selectArr) {
            if ("jobSalary" == this.selectArr[i].expectLeftCode) {
              this.selectArr.splice(i, 1);
            }
          }
        }
        const obj = {};
        obj.expectPosition = this.subCategories[i].labelDesc;
        obj.expectPositionCode = this.subCategories[i].labelCode;
        obj.expectLeftCode = this.categories[this.currentIndex].labelCode;
        this.selectArr.push(obj);
      }
    },

    onSure() {
      this.expectPositionMap = JSON.parse(JSON.stringify(this.selectArr));
      let result = {};
      for (let i in this.expectPositionMap) {
        if (!result[this.expectPositionMap[i].expectLeftCode]) {
          result[this.expectPositionMap[i].expectLeftCode] = [];
        }
        result[this.expectPositionMap[i].expectLeftCode].push(
          this.expectPositionMap[i]
        );
      }
      for (let j in result) {
        result[j] = result[j].map((item) => {
          if (
            ["workDescription", "interviewRequirements", "jobWelfare"].indexOf(
              j
            ) > -1
          ) {
            return item.expectPosition;
          }
          return item.expectPositionCode;
        });
        result[j] = result[j].join(",");
      }
      this.$emit("change", result);
      this.$emit("update:show", false);
    },

    onClear() {
      this.selectArr = [];
    },
    handleClose() {
      if (!this.show) return;
      this.$emit("update:show", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.f20 {
  font-size: 0.2rem;
}

.flex-row {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.desired-position {
  width: 100%;
  position: relative;
  background: #fff;
  overflow: hidden;
  z-index: 2;
  .selected-position-container {
    .header-container {
      padding: 0.3rem;
      .title {
        font-size: 0.28rem;
        font-weight: bold;
        color: #000000;
      }
    }
    .header-content {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      .left-btn {
        width: 6.2rem;
        .btn-box {
          width: auto;
          height: 0.48rem;
          line-height: 0.48rem;
          text-align: center;
          border-radius: 0.06rem;
          margin-right: 0.2rem;
          margin-top: 0.2rem;
          display: flex;
          flex-direction: row;
          background: #3c37d2;
          color: #fff;
          padding: 0rem 0.3rem;
          .text {
            height: 0.2rem;
            line-height: 0.2rem;
            margin: 0.14rem 0.1rem;
            width: auto;
            font-size: 0.24rem;
          }
          .img-shanchu {
            height: 0.2rem;
            line-height: 0.2rem;
            width: 0.2rem;
            margin-top: 0.14rem;
          }
        }
      }
      .sure {
        width: 0.48rem;
        line-height: 0.8rem;
      }
    }
  }
}

.options-container {
  width: 100%;
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  height: 8.61rem;
  .root-list {
    background: #fff;
    width: 3.05rem;
    height: 8.61rem;
    overflow-y: scroll;
    overflow-x: hidden;
    .category-list {
      width: 3.05rem;
      margin: 0 auto;
      .category-item {
        height: 0.7rem;
        overflow: hidden;
        width: 2.3rem;
        font-size: 0.24rem;
        line-height: 0.7rem;
        margin-left: 0.4rem;
        border-bottom: 0.01rem solid #f1f1f1;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .category-content {
    width: 4.4rem;
    background: #f6f6f6;
    height: 8.61rem;
    overflow: scroll;
    .category-list {
      width: 100%;
      .item {
        height: 0.7rem;
        padding-left: 0.2rem;
        overflow: hidden;
        font-size: 0.24rem;
        line-height: 0.7rem;
        display: flex;
        .sub-title {
          width: 3.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .selectIcon {
          height: 0.7rem;
          .img-xuanzhong {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
    }
  }
}

.bottom-content {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.2rem;
  align-items: center;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;

  .clear {
    border-radius: 0.1rem;
    font-size: 0.24rem;
    height: 0.6rem;
    line-height: 0.6rem;
    overflow: visible;
    margin-right: 0.2rem;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    background: #f5f5f5;
  }
  .sure {
    border-radius: 0.1rem;
    font-size: 0.24rem;
    height: 0.6rem;
    line-height: 0.6rem;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3c37d2;
    padding: 0 0.4rem;
    color: #fff;
  }
}

.isSelected {
  color: #3c37d2;
}
.subIcon {
  width: 0.2rem;
  height: 0.2rem;
  background: #f6f6f6;
}
</style>
