<template>
  <div class="wrapper">
    <div class="row">
      <div
        @click="jobCategoryShow = true"
        :class="['job-category', jobCategoryLength ? 'checked' : 'unchecked']"
      >
        <span class="bar">工种</span>
        <span class="bar" v-if="jobCategory.length">·</span>
        <span class="bar" v-if="jobCategory.length">{{
          jobCategory.length
        }}</span>
        <div class="triangle"></div>
      </div>

      <!-- 学历、薪资、求职类型、面试要求、工作福利、工作描述筛选框 -->
      <div
        @click="picksShow = true"
        :class="['seven-filter', picksLength ? 'checked' : 'unchecked']"
      >
        <span class="bar">筛选</span>
        <span class="bar" v-if="picksLength">·</span>
        <span class="bar" v-if="picksLength">{{ picksLength }}</span>
        <div class="triangle"></div>
      </div>
    </div>

    <!-- 工种选择框 -->
    <job-select-category
      :show.sync="jobCategoryShow"
      @change="jobCategoryChange"
    />
    <!-- 学历、薪资、求职类型、面试要求、工作福利、工作描述筛选框 -->
    <job-select-filter :show.sync="picksShow" @change="picksChange" />
  </div>
</template>
<script>
import JobSelectCategory from "./job-select-category";
import JobSelectFilter from "./job-select-filter.vue";

export default {
  data() {
    return {
      jobCategory: [], //职业类型
      picks: {}, // 筛选7个条件
      jobCategoryShow: false, //工种
      picksShow: false,
    };
  },
  components: {
    JobSelectCategory,
    JobSelectFilter,
  },
  computed: {
    select() {
      const obj = {};
      obj.picks = this.picks;
      obj.jobCategory = this.jobCategory;
      return obj;
    },
    keys() {
      return Object.keys(this.picks).length;
    },
    picksLength() {
      let resultArr = Object.values(this.picks);
      let resultArrSplit = resultArr.join(",");
      if (!resultArrSplit) {
        return 0;
      }
      return resultArrSplit.split(",").length;
    },
    jobCategoryLength() {
      return this.jobCategory.length;
    },
  },
  methods: {
    jobCategoryChange(item) {
      this.jobCategory = item;
      this.$emit("selectChange", this.select);
    },

    picksChange(item) {
      this.picks = item;
      this.$emit("selectChange", this.select);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  box-sizing: border-box;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-category {
  margin-right: 0.3rem;
  display: flex;
  align-items: baseline;
  padding: 0.08rem 0.15rem;
}
.seven-filter {
  display: flex;
  align-items: baseline;
  padding: 0.08rem 0.15rem;
}

.bar {
  height: 0.33rem;
  font-size: 0.24rem;
  font-weight: 400;
  line-height: 0.38rem;
  color: #999;
}
.checked {
  background: #e2e1f8;
  .bar {
    color: #3c37d2;
  }
  .triangle {
    border-right: 0.08rem solid #3c37d2;
  }
}
.unchecked {
  background: #f5f5f5;
}
.triangle {
  width: 0;
  height: 0;
  border-right: 0.08rem solid #666666;
  border-top: 0.08rem solid transparent;
  margin-left: 0.1rem;
}
</style>
