var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-popup',{attrs:{"value":_vm.show,"closeable":"","close-icon-position":"top-left","position":"bottom","close-on-click-overlay":false},on:{"click-overlay":_vm.handleClose}},[_c('div',{staticClass:"desired-position"},[_c('div',{staticClass:"selected-position-container flex-column"},[_c('div',{staticClass:"header-container flex-row"},[_c('div',{staticClass:"title"},[_vm._v("已选职位·"+_vm._s(_vm.selectArr.length))])])]),_c('div',{staticClass:"options-container"},[_c('div',{staticClass:"root-list"},[_c('div',{staticClass:"category-list"},_vm._l((_vm.categories),function(cate,index){return _c('div',{key:index,class:[
              'category-item',
              _vm.currentIndex == index ? 'isSelected' : '',
            ],attrs:{"border":false},on:{"click":function($event){return _vm.select(index)}}},[_c('div',{staticClass:"cate-title"},[_vm._v(_vm._s(cate.categorieName))])])}),0)]),_c('div',{staticClass:"category-content"},[_c('div',{staticClass:"category-list"},_vm._l((_vm.subCategories),function(subCate,index){return _c('div',{key:index,class:[
              'item',
              _vm.selectArrDesc.indexOf(subCate.categorieName) > -1
                ? 'isSelected'
                : '',
            ],attrs:{"border":false},on:{"click":function($event){return _vm.selectSub(index)}}},[_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(subCate.categorieName))]),(_vm.selectArrDesc.indexOf(subCate.categorieName) > -1)?_c('div',{staticClass:"selectIcon"},[_c('img',{staticClass:"img-xuanzhong",attrs:{"src":require("@/assets/icon_xuanzhong.png")}})]):_vm._e()])}),0)])]),_c('div',{staticClass:"bottom-content"},[_c('div',{staticClass:"clear",on:{"click":_vm.onClear}},[_vm._v("重置")]),_c('div',{staticClass:"sure",on:{"click":_vm.onSure}},[_vm._v("确定")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }