<template>
  <div class="wrapper">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" disabled>
      <div class="info" v-if="showInfo">
        <div style="font-size: 0">
          <img src="./img/4.jpeg" style="width: 100%" alt="" />
        </div>
        <div class="info-text">
          <p class="p1">航空港区2023年“来腾飞的地方”首场招聘会</p>
          <p class="p2">时间: 2023年2月15日 (星期三) 上午9:30</p>
          <p class="p2">地点: 中国中原人力资源服务产业园西配楼二楼招聘大厅</p>
        </div>
      </div>

      <div class="job-tag-select">
        <job-tag @change="tagChange"></job-tag>
        <job-select @selectChange="selectChange"></job-select>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="job-item-wrapper" v-for="item in list" :key="item.jobCode">
          <div class="item-box">
            <JobItem :data="item" />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import JobTag from "./components/job-tag";
import JobSelect from "./components/job-select";
import JobItem from "@/components/JobItem";
export default {
  name: "",
  components: { JobItem, JobTag, JobSelect },
  data() {
    return {
      list: [],
      loading: false,
      noMore: false,

      finished: false,
      refreshing: false,
      pageSize: 10,
      pageNum: 1,
      showInfo: false,

      jobCategory: [], //工种
      picks: {},

      tag: 2,
    };
  },
  computed: {},
  watch: {},
  // 20231124 为人力资源兼容title显示
  created() {
    const name = this.$route.name;
    if (name === "jobR") document.title = "郑州航空港人力资源综合服务中心";
  },
  mounted() {
    this.search();
    const { s } = this.$route.query;
    if (s == 1) this.showInfo = true;
  },
  methods: {
    selectChange(item) {
      this.pageNum = 1;
      this.jobCategory = item.jobCategory; //工种
      this.picks = item.picks;

      this.initPageData();
      this.search();
    },
    initPageData() {
      this.pageNum = 1;
      this.loading = false;
      this.noData = false;
      this.noMore = false;
    },
    onLoad() {
      this.pageNum++;
      this.search();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageNum = 1;
      this.search();
    },
    //获取培训列表
    search() {
      console.log(this.picks);

      this.loading = true;
      const { pageNum, pageSize } = this;
      const jobCategory = this.jobCategory.join(",");
      const params = {
        pageNum,
        pageSize,
        cityName: "郑州市",
        proName: "河南省",
        districtName: "航空港区",
        lat: "34.52532",
        lng: "113.854726",
        jobCategory,
        type: "1" == this.tag ? "1" : "",
        labels: JSON.stringify({
          workDescription: this.picks.workDescription,
          jobWelfare: this.picks.jobWelfare,
          interviewRequirements: this.picks.interviewRequirements,
        }),

        academicRequirement: this.picks.academicRequirement || "",
        jobSalary: this.picks.jobSalary || "",
        workYears: this.picks.workYears || "",
        workType: this.picks.workType || "",
      };
      this.$store
        .dispatch("job/getIndexJobList", params)
        .then((res) => {
          if (!res) {
            this.finished = true;
            this.noData = true;
            return;
          }
          if (this.pageNum === 1) {
            this.list = res.data.list;
          } else {
            this.list = [...this.list, ...res.data.list];
          }
          this.loading = false;
          this.refreshing = false;

          //判断全部加载完毕
          if (!res.data.isHashNextPage) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
        });
    },
    tagChange(index) {
      this.tag = index;
      this.initPageData();
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  background: #fff;
}
.info-text {
  padding: 0.2rem 0.3rem;
  .p1 {
    font-size: 0.44rem;
    line-height: 0.6rem;
    font-weight: bold;
  }
  .p2 {
    font-size: 0.28rem;
    line-height: 0.5rem;
  }
}
.job-item-wrapper {
  box-sizing: border-box;
  padding: 0.1rem 0.2rem;
  background: #f0f5f7;
  .item-box {
    padding: 0.1rem 0.1rem 0.1rem 0.3rem;
    background: #fff;
  }
}
.job-tag-select {
  padding: 0.2rem 0.3rem;
  font-size: 0.5rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
</style>
