export const categories = [
  {
    labelCode: "academicRequirement",
    labelDesc: "学历",
    childJobLabelVOList: [
      // 1.不限 2.小学 3.初中 4.普通高中 5.技工学校  6.职业高中  7.中等专科 8.大学专科 9.大学本科 10.硕士研究生  11.博士研究生）
      {
        labelCode: 1,
        labelDesc: "不限",
        labelParentCode: 1
      },{
        labelCode: 2,
        labelDesc: "小学",
        labelParentCode: 1
      },
      {
        labelCode: 3,
        labelDesc: "初中",
        labelParentCode: 1
      },
      {
        labelCode: 4,
        labelDesc: "普通高中",
        labelParentCode: 1
      },
      {
        labelCode: 5,
        labelDesc: "技工学校",
        labelParentCode: 1
      },
      {
        labelCode: 6,
        labelDesc: "职业高中",
        labelParentCode: 1
      },
      {
        labelCode: 7,
        labelDesc: "中等专科",
        labelParentCode: 1
      },
      {
        labelCode: 8,
        labelDesc: "大学专科",
        labelParentCode: 1
      },
      {
        labelCode: 9,
        labelDesc: "大学本科",
        labelParentCode: 1
      },
      {
        labelCode: 10,
        labelDesc: "硕士研究生",
        labelParentCode: 1
      },
      {
        labelCode: 11,
        labelDesc: "博士研究生",
        labelParentCode: 1
      }
    ]
  },
  {
    labelCode: "jobSalary",
    labelDesc: "薪资",
    childJobLabelVOList: [
      // 不限  3k以下 3-5k 5-7k 7-9k 9-12k 12k以上
      {
        labelCode: 1,
        labelDesc: "3k以下",
        labelParentCode: 2
      },
      {
        labelCode: 2,
        labelDesc: "3-5k",
        labelParentCode: 2
      },
      {
        labelCode: 3,
        labelDesc: "5-7k",
        labelParentCode: 2
      },
      {
        labelCode: 4,
        labelDesc: "7-9k",
        labelParentCode: 2
      },
      {
        labelCode: 5,
        labelDesc: "9-12k",
        labelParentCode: 2
      },
      {
        labelCode: 6,
        labelDesc: "12k以上",
        labelParentCode: 2
      }
    ]

  },
  {
    labelCode: "workYears",
    labelDesc: "经验",
    childJobLabelVOList: [
      // 0-不限，1-1年，2-2年，3-3年，4-4年，5-5年以上
      {
        labelCode: 0,
        labelDesc: "不限",
        labelParentCode: 2
      },{
        labelCode: 1,
        labelDesc: "1年",
        labelParentCode: 2
      },
      {
        labelCode: 2,
        labelDesc: "2年",
        labelParentCode: 2
      },
      {
        labelCode: 3,
        labelDesc: "3年",
        labelParentCode: 2
      },
      {
        labelCode: 4,
        labelDesc: "4年",
        labelParentCode: 2
      },
      {
        labelCode: 5,
        labelDesc: "5年以上",
        labelParentCode: 2
      },
    ]
  },
  {
    labelCode: "workType",
    labelDesc: "求职类型",
    childJobLabelVOList: [
      // 1-全职 2-兼职 3-日结 4-实习 5-短期工"
      {
        labelCode: 1,
        labelDesc: "全职",
        labelParentCode: 2
      },
      {
        labelCode: 2,
        labelDesc: "兼职",
        labelParentCode: 2
      },
      {
        labelCode: 3,
        labelDesc: "日结",
        labelParentCode: 2
      },
      {
        labelCode: 4,
        labelDesc: "实习",
        labelParentCode: 2
      },
      {
        labelCode: 5,
        labelDesc: "短期工",
        labelParentCode: 2
      }
    ]
  },

  {
    
    labelCode: "interviewRequirements",
    labelDesc: "面试要求",
    childJobLabelVOList: [],
  },
  {
    labelCode: "jobWelfare",
    labelDesc: "工作福利",
    childJobLabelVOList: [],

  },
  {
    labelCode: "workDescription",
    labelDesc: "工作描述",
    childJobLabelVOList: []

  }
];

export const subCategories = JSON.parse(JSON.stringify(categories[0].childJobLabelVOList));